import { useState } from "react";
import styles from "./ArrowAccordion.module.css";

function ArrowAccordion({ data }) {
    const [activeRow, setActiveRow] = useState(0);
    return (
        <div className={styles.arrow_accordion}>
            {data.map((obj, index) => (
                <div
                    key={index}
                    className={[
                        styles.row,
                        index === activeRow ? styles.active : "",
                    ].join(" ")}
                    onClick={() => setActiveRow(index)}
                >
                    <div className={styles.index}>
                        {(index + 1).toString().padStart(2, "0")}
                    </div>
                    <div className={styles.middle}>
                        <div className={styles.question}>{obj.q}</div>
                        <div className={styles.answer}>{obj.a}</div>
                    </div>
                    <button className={styles.right}>
                        <svg
                            className={styles.arrow_svg}
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.00008 1.16666L7.00008 12.8333M7.00008 12.8333L1.16675 6.99999M7.00008 12.8333L12.8334 6.99999"
                                // stroke="#002C61"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default ArrowAccordion;
