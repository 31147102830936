import styles from "./AdvisorCard.module.css";
import IconLink from "../IconLink/IconLink";
// import { useState } from "react";

function AdvisorCard({ name, role, title, linkedinUrl }) {
    // const [expanded, setExpanded] = useState(false);

    // function handleExpandClick() {
    //     setExpanded((prev) => !prev);
    // }

    return (
        <div className={styles.advisor_card}>
            <div className={styles.left}>
                <img
                    src={`/images/headshots/small/${name
                        .toLowerCase()
                        .replace(/ /g, "-")}-headshot-small.png`}
                    alt=""
                />
            </div>
            <div className={styles.right}>
                <div className={styles.top}>
                    <div className={styles.name}>{name}</div>
                    {linkedinUrl && (
                        <IconLink
                            style={{
                                width: 16,
                                height: 16,
                                opacity: 0.5,
                            }}
                            src={"/images/linkedin-logo.svg"}
                            href={linkedinUrl}
                            alt={`Link to ${name}'s LinkedIn profile`}
                        />
                    )}
                </div>
                <div className={styles.bottom}>
                    <div className={styles.title}>{title}</div>
                </div>
            </div>
        </div>
    );
}

export default AdvisorCard;
