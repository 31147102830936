import Header from "../../components/Header/Header";
import styles from "./MindPage.module.css";
import Footer from "../../components/Footer/Footer";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import TabbedCarousel from "../../components/TabbedCarousel/TabbedCarousel";
import { useEffect, useRef } from "react";
import { ourApproach } from "../../data/verticals";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import TabbedAccordion from "../../components/TabbedAccordion/TabbedAccordion";
import WhiteTextSection from "../../components/WhiteTextSection/WhiteTextSection";
// import HorizontalScrollSection from "../../components/HorizontalScrollSection/HorizontalScrollSection";
import ToolCard from "../../components/ToolCard/ToolCard";
import NumberedGridList from "../../components/NumberedGridList/NumberedGridList";
import { ourFeatures } from "../../data/lists";
import { mindFAQ } from "../../data/faq";
import ArrowAccordion from "../../components/ArrowAccordion/ArrowAccordion";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import { mindTestimonials } from "../../data/testimonials";
// import keelLabIconSmall from "../assets";

function MindPage() {
    const videoContainerRef = useRef(null);
    const videoRef = useRef(null);
    const whoWeAreRef = useRef(null);

    useEffect(() => {
        document.title = "Mind | Keel Digital Solutions";
    }, []);

    function handleVideoCoverClick() {
        videoContainerRef.current.classList.remove(styles.show_cover);
        videoRef.current.play();
    }

    function handleLearnMoreClick() {
        whoWeAreRef.current.scrollIntoView({
            behavior: "smooth",
        });
    }

    return (
        <div id={styles.mind}>
            <Header logoSrcLight="/images/keel-mind-logo-white.svg" />
            <main>
                <div id={styles.hero}>
                    <div id={styles.hero_card}>
                        <ArrowSubtitle text={"Welcome to Keel Mind"} />
                        <div id={styles.hero_card_top}>
                            <h1 className="main_h1">
                                The evolution of mental health care
                            </h1>
                        </div>
                        <div id={styles.hero_subtitle}>
                            <div>
                                We’re an AI-driven platform that facilitates
                                behavioral health training and education while
                                delivering measurement-based care.
                            </div>
                            <div>goes beyond... "how do you feel?"</div>
                        </div>
                        <button
                            id={styles.hero_learn_more}
                            onClick={handleLearnMoreClick}
                            className="orange_button"
                        >
                            Learn More
                            <img
                                src="/images/small-right-arrow-white.svg"
                                alt=""
                            />
                        </button>

                        <div
                            id={styles.hero_video_container}
                            ref={videoContainerRef}
                            className={styles.show_cover}
                        >
                            <video
                                ref={videoRef}
                                id={styles.hero_video}
                                controls={true}
                                src="/videos/keel-mind-hero-video.mp4"
                            ></video>
                            <div
                                onClick={handleVideoCoverClick}
                                id={styles.hero_video_cover}
                            >
                                <img
                                    alt="A freeze frame of Ahad Bandealy, Chief Digital Officer of Keel Digital Solutions, standing in front of a man and a woman facing each other in lounge chairs."
                                    src="/images/keel-mind-hero-video-thumbnail.png"
                                    id={styles.hero_video_image_cover}
                                />
                                <div id={styles.hero_video_color_cover}></div>
                                <button
                                    onClick={handleVideoCoverClick}
                                    id={styles.hero_video_play_button}
                                >
                                    <img
                                        draggable={false}
                                        src="/images/keel-mind-hero-video-play-icon.png"
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                        <div id={styles.schools_container}>
                            <img
                                src="/images/mind-hero-logos/ccapp_logo.png"
                                alt="CCAPP logo"
                            />
                            <img
                                src="/images/mind-hero-logos/humber_logo.png"
                                alt="Humber College logo"
                            />
                            <img
                                src="/images/mind-hero-logos/mcmaster_logo.png"
                                alt="McMaster University logo"
                            />
                            <img
                                src="/images/mind-hero-logos/lakehead_logo.png"
                                alt="Lakehead University logo"
                            />
                            <img
                                src="/images/mind-hero-logos/six_nations_logo.png"
                                alt=""
                            />
                            <img
                                src="/images/mind-hero-logos/berkeley_logo.png"
                                alt="UC Berkeley Logo"
                            />
                            <img
                                src="/images/mind-hero-logos/waterloo_logo.png"
                                alt="University of Waterloo logo"
                            />
                            <img
                                src="/images/mind-hero-logos/carleton_logo.png"
                                alt="Carleton University logo"
                            />
                            <img
                                src="/images/mind-hero-logos/kenora_cao_logo.png"
                                alt="Kenora Chiefs Advisory Ogimaawabiitong logo"
                            />
                        </div>
                    </div>
                </div>
                <WhiteTextSection
                    reference={whoWeAreRef}
                    arrowText={"Who we are"}
                >
                    We’re a <span>revolutionary online platform</span> that
                    provides more efficient training and monitoring for
                    educators, plus a suite of powerful decision support tools
                    combined with time-saving automated functions.
                </WhiteTextSection>
                <div id={styles.mind_content}>
                    <section id={styles.our_approach}>
                        <SectionHeading
                            arrowText={"Our approach"}
                            darkArrowSubtitle={true}
                            heading={
                                "Keel Digital Solutions is a business to government and government-agency company"
                            }
                        >
                            Four key verticals:
                        </SectionHeading>
                        <TabbedCarousel
                            data={ourApproach}
                            className={"small-hide"}
                        />
                        <TabbedAccordion
                            data={ourApproach}
                            className={"small-show"}
                        />
                    </section>
                    <section>
                        <SectionHeading
                            mainStyle={{
                                marginBottom: 0,
                                paddingBottom: 80,
                                maxWidth: 900,
                            }}
                            arrowText={"Our tools"}
                            heading={
                                "Innovative AI solutions to support every mental health journey"
                            }
                            darkArrowSubtitle={true}
                        />
                        <div id={styles.tool_cards_container}>
                            <ToolCard
                                iconSrc={
                                    "/images/tools/self-assessment-icon.png"
                                }
                                iconAlt={
                                    "icon of a man holding a phone, standing beside a large laptop, with a piece of paper containing a list leaning against the laptop screen."
                                }
                                name={"Self Assessment"}
                                description={
                                    "A tool that allows our clients to gain quick insights into their overall mental readiness and decide when it’s the right time to seek help."
                                }
                            />

                            <ToolCard
                                iconSrc={"/images/tools/keel-reels-icon.png"}
                                iconAlt={
                                    "icon of a man sitting in front of a laptop, holding a hand up, with a message speech bubble above his hand."
                                }
                                name={"Keel Reels"}
                                description={
                                    "An AI-driven chatbot interview that improves rapport between care providers and clients based on story-telling and lived experience."
                                }
                            />

                            <ToolCard
                                iconSrc={
                                    "/images/tools/natural-language-processing-icon.png"
                                }
                                iconAlt={""}
                                name={"Natural Language Processing"}
                                description={
                                    "Private and virtual video sessions, AI decision support tools, and analytics provides an expansive toolbox to increase care provider productivity."
                                }
                            />

                            <ToolCard
                                iconSrc={
                                    "/images/tools/automated-notes-icon.png"
                                }
                                iconAlt={
                                    "icon of a 3D line graph and circle graph showing sample data, with an avatar of a person and a message icon."
                                }
                                name={"Automated Notes & Tasks"}
                                description={
                                    "Formats session transcripts into editable notes, saving time and enabling providers to focus on care while improving oversight for supervisors."
                                }
                            />
                        </div>
                    </section>

                    <section id={styles.our_features}>
                        <SectionHeading
                            arrowText={"Our features"}
                            darkArrowSubtitle={true}
                            heading={
                                "What makes the Keel Mind platform uniquely different"
                            }
                        />

                        <NumberedGridList data={ourFeatures} />
                    </section>

                    <section id={styles.our_evidence}>
                        <div
                            className="big_dark_card"
                            id={styles.evidence_card}
                        >
                            <SectionHeading
                                arrowText={"Our evidence"}
                                heading={"Data-driven, high-quality care"}
                                headingStyle={{
                                    color: "white",
                                }}
                                textStyle={{ color: "white" }}
                            />
                            <div className={styles.evidence_container}>
                                <div className={styles.evidence}>
                                    <strong>83%</strong>
                                    <div>
                                        Feel an improvement in their
                                        <br />
                                        mental health & wellness.
                                    </div>
                                </div>
                                <div className={styles.evidence}>
                                    <strong>84%</strong>
                                    <div>
                                        Saliva tests taken
                                        <br />
                                        with quick results
                                    </div>
                                </div>
                                <div className={styles.evidence}>
                                    <strong>90%</strong>
                                    <div>
                                        Feel the platform is
                                        <br />
                                        easy to access and use.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        id={styles.testimonials_section}
                        className={styles.full}
                    >
                        <TestimonialCarousel
                            heading={
                                "Delivering meaningful & effective results"
                            }
                            arrowText={"Our impact"}
                            darkArrowSubtitle={true}
                            data={mindTestimonials}
                        />
                    </section>
                    <section id={styles.faq_section} className={styles.full}>
                        <SectionHeading
                            arrowText={"FAQ"}
                            darkArrowSubtitle={true}
                            heading={"Answers to common questions"}
                        />
                        <ArrowAccordion data={mindFAQ} />
                    </section>
                </div>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default MindPage;
