import styles from "./SolutionComponent.module.css";
import { Link } from "react-router-dom";

function SolutionComponent({
    iconSrc,
    Graphic = () => {},
    heading,
    children,
    buttonHref,
    buttonText = "Learn More",
    reversed = false,
    shortenText = false,
    style,
}) {
    return (
        <div
            className={[
                styles.solution_component,
                reversed ? styles.reversed : undefined,
            ].join(" ")}
            style={style}
        >
            <div className={styles.left}>
                {iconSrc && <img src={iconSrc} alt={heading + " icon"} />}
                <h3 className={styles.heading}>{heading}</h3>
                <p
                    className={styles.description}
                    style={shortenText ? { paddingRight: 75 } : {}}
                >
                    {children}
                </p>
                <Link
                    className={["orange_button", styles.button].join(" ")}
                    to={buttonHref}
                >
                    {buttonText}
                    <img
                        src="/images/small-right-arrow-white.svg"
                        alt="arrow pointing right"
                    />
                </Link>
            </div>
            <div className={styles.right}>
                <Graphic />
            </div>
        </div>
    );
}

export default SolutionComponent;
