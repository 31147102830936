import React from "react";
import ReactDOM from "react-dom/client";
import "./keyframes.css";
import "./globals.css";
import "./fonts.css";
import HomePage from "./pages/Home/HomePage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LeadershipPage from "./pages/Leadership/LeadershipPage";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import ContactUsPage from "./pages/ContactUs/ContactUsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/TermsOfUse/TermsOfUsePage";
import LabsPage from "./pages/Labs/LabsPage";
import MindPage from "./pages/Mind/MindPage";
import TermsAndConditionsPage from "./pages/TermsAndConditions/TermsAndConditionsPage";
import PageChangeHandler from "./components/PageChangeHandler/PageChangeHandler";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Root() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <PageChangeHandler />
                <Routes>
                    <Route path="*" element={<HomePage />} />
                    <Route path="mind" element={<MindPage />} />
                    <Route path="lab" element={<LabsPage />} />
                    <Route path="our-team" element={<LeadershipPage />} />
                    <Route path="about-us" element={<AboutUsPage />} />
                    <Route path="contact-us" element={<ContactUsPage />} />
                    <Route
                        path="privacy-policy"
                        element={<PrivacyPolicyPage />}
                    />
                    <Route path="terms-of-use" element={<TermsOfUsePage />} />
                    <Route
                        path="terms-and-conditions"
                        element={<TermsAndConditionsPage />}
                    />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default LeadershipPage;
