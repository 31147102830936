export const timelineData = [
    {
        year: 2021,
        title: "Vector Health Labs was founded",
        description: "",
    },
    {
        year: 2021,
        title: "Acquired FIQ (Lab)",
        description: "",
    },
    {
        year: 2022,
        title: "Acquired Get A-Head",
        description: "",
    },
    {
        year: 2022,
        title: "1st contract with Ministry of Colleges & Universities",
        description: "",
    },
    {
        year: 2023,
        title: "Rebranded as Keel Digital Solutions Corp",
        description: "",
    },
    {
        year: 2023,
        title: "Launched with Indigenous Affairs",
        description: "",
    },
    {
        year: 2024,
        title: "Partnered with Thymia",
        description: "",
    },
    {
        year: 2024,
        title: "Partnered with Real Human Project",
        description: "",
    },
    {
        year: 2024,
        title: "Launched peer support program",
        description: "",
    },
    {
        year: 2024,
        title: "Partnered with CACCF",
        description: "",
    },
];
