import styles from "./NumberedGridList.module.css";

function NumberedGridList({ data, flow = "row" }) {
    return (
        <div className={styles.values_grid} style={{ gridAutoFlow: flow }}>
            {data.map((value, index) => (
                <div key={index} className={styles.value}>
                    <div className={styles.value_number}>
                        {`${index + 1}`.padStart(2, "0")}
                    </div>
                    <div className={styles.value_content}>
                        <strong>{value.title}</strong>
                        <p>{value.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NumberedGridList;
