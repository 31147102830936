import { useEffect } from "react";
import styles from "./ContactUsPage.module.css";
import Header from "../../components/Header/Header";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import Footer from "../../components/Footer/Footer";
// import { Link } from "react-router-dom";
import ContactForm from "../../components/ContactForm/ContactForm";

function ContactUsPage() {
    useEffect(() => {
        document.title = "Contact us | Keel Digital Solutions";
    }, []);
    return (
        <div>
            <Header dark={true} />
            <main id={styles.main}>
                <section id={styles.contact_section}>
                    <div className={styles.left}>
                        <div className={styles.top}>
                            <h1 className="main_h1">
                                We'd love to hear from you
                            </h1>
                            <p>
                                Whether you are interested in booking a demo,
                                learning more about our solutions, or have any
                                questions, we'd be happy to connect with you.
                            </p>
                            <p>
                                Please email us and someone will be in contact
                                with you as soon as possible.
                            </p>
                        </div>
                        {/* <Link
                            id={styles.email_link}
                            className={"orange_button"}
                            to={`mailto:admin@keel.digital`}
                        >
                            <span>admin@keel.digital</span>
                            <img src="/images/up-right-arrow.svg" alt="" />
                        </Link> */}
                        <div className={styles.bottom}>
                            <div className={styles.location}>
                                <strong>Keel Toronto</strong>
                                <div className={styles.address}>
                                    <div>
                                        <div>2 Bloor St E Suite 3501,</div>
                                        <div>Toronto, ON M4W 1A8</div>
                                        <div>Canada</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.location}>
                                <strong>Keel Miami</strong>
                                <div className={styles.address}>
                                    <div>2196 N Miami Ave. 6th Floor,</div>
                                    <div>Miami, Fl 33127</div>
                                    <div>United States</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <ContactForm />
                    </div>
                </section>
                <ColorBanner hideLink={true} />
            </main>
            <Footer />
        </div>
    );
}

export default ContactUsPage;
