import styles from "./OverlayedImage.module.css";

function OverlayedImage({
    children,
    src,
    alt,
    className,
    id,
    style,
    imgStyle,
}) {
    return (
        <div
            className={[styles.container, className].join(" ")}
            id={id}
            style={style}
        >
            <picture>
                {/* <source srcSet={smallSrc} media="(max-width: 1370px)" /> */}
                <img
                    style={imgStyle}
                    src={src}
                    className={styles.image}
                    alt={alt}
                />
            </picture>
            {children}
        </div>
    );
}

export default OverlayedImage;
