import styles from "./Modal.module.css";
import useFocusTrap from "../../hooks/useFocusTrap";
import { useRef } from "react";

function Modal({ modalStyle, showModal, setShowModal, children }) {
    const modalRef = useRef(null);
    useFocusTrap(modalRef, showModal, {
        initialFocus: "first",
    });

    function handleClose() {
        setShowModal(false);
    }

    function handleKeyDown(e) {
        if (e.keyCode === 27) {
            setShowModal(false);
        }
    }

    return (
        <div
            ref={modalRef}
            onKeyDown={handleKeyDown}
            className={[
                styles.modal_backdrop,
                showModal ? styles.show_modal : null,
            ].join(" ")}
        >
            <div className={styles.modal} style={modalStyle}>
                <div className={styles.content_wrapper}>
                    {children}
                    <div className={styles.close_button_section}>
                        <button
                            className={styles.close_button}
                            onClick={handleClose}
                        >
                            <img src="/images/modal-close-icon.svg" alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
