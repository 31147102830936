export const companyValues = [
    {
        title: "Client success",
        description:
            "We are dedicated to offering our clients continuous support and ensuring their success from the start through live demonstrations, training sessions, and proactive communication.",
    },
    {
        title: "Company wide",
        description:
            "Our commitment to mental health is embedded in our core principles. We ensure that our employees feel valued, heard, and supported.",
    },
    {
        title: "Science-backed research",
        description:
            "We are guided by the latest research, data, and evidence in mental health and endocrinology, ensuring that the latest scientific findings and robust evidence inform our practices.",
    },
    {
        title: "Expert collaboration",
        description:
            "We have a robust advisory team with expertise that spans technology, physical, and mental health disciplines across North America.",
    },
];

export const ourFeatures = [
    {
        title: "Advanced supervision",
        description:
            "Enables supervisors to monitor sessions, gather insights, and provide feedback all in real time.",
    },
    {
        title: "Outcome measures",
        description:
            "Distribute, collect, and track mental health symptoms and outcomes over time.",
    },
    {
        title: "Matching algorithm",
        description:
            "A questionnaire that matches care providers to the client’s unique needs.",
    },
    {
        title: "Practice management",
        description:
            "Trainee files, notes, transcripts and recordings are all available in a single location.",
    },
    {
        title: "Keel Mind Academy",
        description:
            "A learning management system for guided lessons, progress tracking, and interactive tools, supporting personalized, self-paced learning.",
    },
    {
        title: "Wellness Hub",
        description:
            "A content management system of videos, podcasts, and articles catered to self-help and exploration.",
    },
];
