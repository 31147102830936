import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import styles from "./SectionHeading.module.css";

function SectionHeading({
    heading,
    children,
    textStyle,
    headingStyle,
    arrowText,
    darkArrowSubtitle,
    mainStyle,
}) {
    return (
        <div className={styles.section_heading} style={mainStyle}>
            {arrowText && (
                <ArrowSubtitle text={arrowText} dark={darkArrowSubtitle} />
            )}
            <h2 className={styles.heading} style={headingStyle}>
                {heading}
            </h2>
            {children && (
                <p className={styles.description} style={textStyle}>
                    {children}
                </p>
            )}
        </div>
    );
}

export default SectionHeading;
