import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Policy from "../../components/Policy/Policy";
// import {  } from "../../utils";

function PrivacyPolicyPage() {
    useEffect(() => {
        document.title = "Privacy Policy | Keel Digital Solutions";
    });
    return (
        <div>
            <Header dark={true} />
            <main>
                <Policy title="Privacy policy">
                    <div>
                        Keel Mind Inc. ("Keel Mind", “we” or “us”), the operator
                        of the keelmind.com website (the “Website”) and the Keel
                        Mind mobile application (the “App”) is committed to
                        protecting any personal information which you may
                        provide while using, or that we may obtain from your use
                        of, the Website or the emails we send to you. The
                        purpose of this privacy policy (the “Policy”) is to
                        inform you of our privacy practices and policies,
                        including:
                        <ul>
                            <li>
                                how and why we collect your personal information
                                on behalf of your organization;
                            </li>
                            <li>
                                how we intend to use your personal information
                                within your organization's interactions;
                            </li>
                            <li>
                                with which parties we may share your personal
                                information; and
                            </li>
                            <li>
                                to provide you with contact information should
                                you have questions or concerns about our
                                practices.
                            </li>
                        </ul>
                    </div>
                    <div>
                        We know that customers care deeply about privacy and
                        data security. That’s why Keel Mind gives you ownership
                        and control over your content through simple, powerful
                        tools that allow you to determine how this data is
                        managed and retained.
                    </div>
                    <div>
                        The Keel Mind platform is a SaaS (software as a service)
                        solution where the Keel Mind organization operates the
                        platform on behalf of the organizations who are our
                        customers. With this in mind, all customer data is owned
                        by the organization customer. Keel Mind is a data
                        custodian of this customer data, and we protect this
                        data on behalf of our organization customer.
                    </div>
                    <div>
                        The impact of the above approach is that Keel Mind is
                        both reliant and supportive of the organization's data
                        retention and data management policies and processes. We
                        provide the tools for manipulating, adding, removing and
                        expunging all the customer data in support of your
                        internal processes. More specifically, this impacts the
                        typical touchpoints:
                        <ul>
                            <li>Data Retention policies</li>
                            <li>Data Privacy policies</li>
                        </ul>
                    </div>
                    <div>
                        BY USING THE WEBSITE, YOU CONSENT TO THE COLLECTION,
                        STORAGE, USE, DISCLOSURE AND RETENTION OF YOUR PERSONAL
                        INFORMATION IN ACCORDANCE WITH THIS POLICY. IF YOU DO
                        NOT AGREE WITH THIS POLICY, YOU MUST NOT ACCESS OR USE
                        THE WEBSITE.
                    </div>
                    <div>
                        <h2>Overview of Our Privacy Practices</h2>
                        Our privacy practices are summarized here. Under this
                        Policy, Keel Mind:
                        <ol>
                            <li>
                                Collects personal information through the
                                Website and the App, in the course of
                                facilitating the delivery of your organization's
                                services, when you make inquiries and for
                                marketing purposes;
                            </li>
                            <li>
                                Collects information relating to individual
                                sessions including audio recordings;
                            </li>
                            <li>
                                May collect data through your Website usage,
                                your internet protocol (IP) address, the Website
                                cookies and email web beacons;
                            </li>
                            <li>
                                May collect non-identifiable information
                                regarding Website users (such as the date and
                                time you accessed the website);
                            </li>
                            <li>
                                Shares this information with its service
                                providers, service providers' supervisors,
                                authorized employees, agents, service providers
                                and legal representations, and may also share
                                this information with third parties that we
                                engage to provide products and services on our
                                behalf;
                            </li>
                            <li>
                                Except as set out in (5), does not share your
                                personal information with third parties without
                                your consent, unless authorized or required by
                                law or as disclosed to you when the information
                                is collected;
                            </li>
                            <li>
                                Collects and uses this information to connect
                                you with and to facilitate counseling services,
                                to comply with applicable laws and regulations,
                                to administer the Website and the App, to
                                protect Keel Mind and Website users from fraud,
                                to contact you with your billing inquiries (when
                                applicable), and to communicate with you (unless
                                you have opted out of receiving such
                                information);
                            </li>
                            <li>
                                Allows you to withdraw your consent to the
                                collection, use and disclosure of your personal
                                information at any time; and
                            </li>
                            <li>
                                Mitigates the risk and impact of potential harms
                                involved in the disclosure of personal
                                information through the adoption of reasonable
                                physical, electronic and managerial procedures
                                appropriate to the sensitivity of the data in an
                                effort to safeguard the personal information you
                                provide to us. Even with these measures, there
                                is a residual risk of the loss of, unauthorized
                                access to or unauthorized disclosure of your
                                information which could result in financial or
                                other harm to you. network information.
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h2>Personal Information Collected by Keel Mind</h2>We
                        maintain information relating to our clients and their
                        orders based upon network information. The information
                        we collect comes directly from you, our counselors, our
                        provided by the organization. Information is collected
                        through the Website and the App and in the course of
                        facilitating the delivery of your organization's
                        services. Only personal information that is reasonably
                        necessary is collected. Types of information collected
                        may include health and wellness data, client data,
                        financial (when applicable), service and computer and
                        network information. The information we collect comes
                        directly from you, our counselors, our Website or App or
                        is information relating to the delivery or performance
                        of our services.
                    </div>
                    <div>
                        You should exercise discretion when disclosing your
                        personal information to us.
                    </div>
                    <div>
                        Service Provision Information: When you first sign up
                        for your organization's service through Keel Mind, you
                        may be asked questions with information collected about:
                        <ul>
                            <li>personal information identifying you;</li>
                            <li>
                                any stressors that you may be experiencing; and
                            </li>
                            <li>
                                any medical information that we should know
                                about.
                            </li>
                        </ul>
                    </div>
                    <div>
                        these questions are used to inform a matching algorithm
                        with service providers within your organization.
                    </div>
                    <div>
                        We also collect information relating to individual
                        sessions including audio/video recordings. These
                        recordings are considered training data and can be
                        considered medical or health data and therefore cannot
                        be retrieved by the client without the express written
                        consent of the service provider(s) and supervisor.
                    </div>
                    <div>
                        Client Information: We may also ask you to provide
                        various information about yourself including your name,
                        street address, postal code, email address, occupation
                        and such other information as may be reasonably
                        requested by us from time to time.
                    </div>
                    <div>
                        Service Information: We may collect personal information
                        from you when you request assistance through our
                        customer service department or when you voluntarily
                        subscribe to a Keel Mind service or newsletter.
                    </div>
                    <div>
                        Computer and Network Information: We may also collect
                        your Website and App usage data and your internet
                        protocol (IP) address, and other similar information
                        that you provide to us via the Website or the App,
                        including information collected through the use of
                        cookies on your browser. As well, we may collect
                        information relating to the emails that we send to you,
                        such as when you open the email, through the use of
                        single pixel gifs, also called web beacons, or other
                        tracking technologies.
                    </div>
                    <div>
                        The Website may automatically collect certain
                        non-identifiable information regarding Website users,
                        such as the date and time you access the Website, the
                        Internet address of the website from which you linked
                        directly to the Website, the operating system you are
                        using, the sections of the Website you visit, the
                        Website pages read and images viewed, and the materials
                        you post to or download from the Website. We may
                        collect, use, transfer and disclose aggregated and/or
                        non-personal information.
                    </div>
                    <div>
                        The Website uses "cookies", a technology that installs
                        information on a website user's computer to permit the
                        Website to recognize future visits using that computer.
                        Cookies enhance the convenience and use of the Website.
                        For example, the information provided through cookies is
                        used to recognize you as a previous user of the Website,
                        to track your activity at the Website in order to
                        respond to your needs, to offer personalized web page
                        content and information for your use, and to otherwise
                        facilitate your Website experience. You may choose to
                        decline cookies if your browser permits, but doing so
                        may affect your use of the Website and your ability to
                        access certain features of the Website.
                    </div>
                    <div>
                        We also include web beacons in the emails that we send
                        to you. Web beacons allow us to collect information
                        about when email recipients open the emails we send,
                        what parts of the email they review, their IP address,
                        browser or email client type and other similar details.
                        Some of this information may be correlated with
                        recipients’ email addresses. We use this information to
                        review how recipients are responding to the emails we
                        send
                    </div>
                    <div>
                        <h2>
                            Purpose of Collection and Use of Your Personal
                            Information
                        </h2>
                    </div>
                    <div>
                        Keel Mind collects and uses personal information that
                        you supply through your use of the Website or the App,
                        from participating in health and wellness sessions, or
                        from the emails we send for the following purposes:
                        <ul>
                            <li>
                                To connect you with and to facilitate counseling
                                services;
                            </li>
                            <li>
                                To maintain records of your use of the Website,
                                the App and counseling services (including
                                transcriptions of any counseling sessions);
                            </li>
                            <li>
                                To process payments for our services and to give
                                refunds (when applicable);
                            </li>
                            <li>
                                To administer the Website and to manage the
                                emails that we send;
                            </li>
                            <li>
                                As permitted by, and to comply with, applicable
                                laws and regulations;
                            </li>
                            <li>
                                To protect Keel Mind and Website users against
                                fraud;
                            </li>
                            <li>
                                To contact you in connection with your billing
                                inquiries;
                            </li>
                            <li>
                                To notify you in connection with any changes to
                                our services or this Policy;
                            </li>
                            <li>
                                To communicate with you about Keel Mind and its
                                products, unless and until you opt- out of
                                receiving such information by contacting us at
                                <a href="mailto:privacy@keel.digital">
                                    privacy@keel.digital
                                </a>
                                ; and
                            </li>
                            <li>Other purposes permitted by law.</li>
                        </ul>
                    </div>
                    <div>
                        <h2>Disclosure of Personal Information</h2>We will keep
                        personal information that you provide to on Keel Mind
                        private, and will disclose such personal information
                        only in accordance with this Policy.
                    </div>
                    <div>
                        Keel Mind does not sell, rent, or lease to third parties
                        any personal information it collects from you. Keel Mind
                        may disclose or share personal information with its
                        authorized employees, agents, service providers and
                        legal representatives for the purposes described in this
                        Policy. We may also share your personal information with
                        third parties that we engage to provide products and
                        services on our behalf. Keel Mind encrypts your
                        information at rest and in transit using
                        industry-standard encryption algorithms. Keel Mind
                        shares your personal information using secure protocols
                        (SSL/TLS or HTTPS) with service providers who assist
                        with administering, providing services, hosting and
                        supporting our Website and App.
                    </div>
                    <div>
                        Keel Mind will not otherwise share your personal
                        information with third parties without your consent,
                        unless authorized or required by law or as disclosed to
                        you when the information is collected. Keel Mind may not
                        disclose your personal information to a government
                        institution or agency that has asserted its lawful
                        authority to obtain the information unless Keel Mind has
                        reasonable grounds to believe the information could be
                        useful in the investigation of unlawful activity, or to
                        comply with a subpoena or warrant or an order made by a
                        court, person or body with jurisdiction to compel the
                        production of information, or to comply with court rules
                        regarding the production of records and information, or
                        to protect Keel Mind’s rights and property. If Keel Mind
                        is involved in the sale or transfer of some or all of
                        its business, Keel Mind may not disclose your personal
                        information in connection with the transfer or sale.
                    </div>
                    <div>
                        <h2>How Keel Mind Protects Your Privacy</h2>Keel Mind
                        keeps the personal information collected through the
                        course of organization's service confidential and has
                        adopted reasonable physical, electronic and managerial
                        procedures appropriate to the sensitivity of the data in
                        an effort to safeguard the personal information you
                        provide to Keel Mind. Notwithstanding these measures, no
                        collection, storage or transmission of information over
                        the Internet on websites or otherwise can be guaranteed
                        to be 100% secure, and therefore we cannot ensure,
                        warrant or guarantee the security of any such
                        information.
                    </div>
                    <div>
                        <h2>Withdrawal of Consent</h2>You may withdraw your
                        consent to the collection, use and disclosure of
                        personal information about you at any time. To do this,
                        please contact our Privacy Officer at the e-mail address
                        listed in this Policy. Withdrawal of your consent to the
                        collection, use and disclosure of personal information
                        may result in you being unable to continue use of our
                        Website or services.
                    </div>
                    <div>
                        <h2>
                            Verifying and Amending your Personal Information
                        </h2>
                        Subject to certain exceptions prescribed by law, you
                        will be given reasonable access to your personal
                        information held by Keel Mind and you may request that
                        your personal information be corrected or updated.
                        Please e-mail our Privacy Officer and we will assist
                        you. You may be required to put your request in writing
                        to confirm your request and identity. It is your
                        responsibility to notify us of any changes to your
                        personal information, including contact information, so
                        we can contact you and inform you about updates to this
                        Policy and any other relevant information with respect
                        to your use of the Website or App.
                    </div>
                    <div>
                        <h2>Retention of Personal Information</h2>We will keep
                        and retain your personal information in compliance with
                        our legal obligations, that of your organization's
                        obligations and only as long as necessary for the
                        purposes for which it was collected.
                    </div>
                    <div>
                        <h2>
                            Storage, transmission, and Location of Information
                        </h2>
                        Keel Mind may store and process your personal
                        information collected through the Website on the servers
                        of our third-party service providers. Data at rest is
                        encrypted and stored on servers located in the Country
                        specified by your service provider. Our service
                        providers are only permitted to store your personal
                        information, and are not otherwise authorized to
                        collect, use or disclose your personal information,
                        except in accordance with the purposes set out in this
                        Policy or as required by applicable law. Unified
                        Communication traffic is encrypted to government level
                        standards, anonymized and processed by a service
                        provider located in the US that adheres to GDPR
                        standards. The chat feature is meant for quick and easy
                        communication between you and your service provider and
                        is not meant for the exchange of personal health
                        information. We strongly encourage you to use this
                        feature as it was intended and avoid divulging any
                        personal or sensitive information. It is important to
                        understand that there are risks unique and specific to
                        telehealth, including but not limited to, the
                        possibility that your sessions or other communication by
                        your service provider to others regarding your care
                        could be disrupted or distorted by technical failures or
                        could be interrupted or could be accessed by
                        unauthorized persons.
                    </div>
                    <div>
                        <h2>Privacy Policy Changes and Updates</h2>We may revise
                        this Policy at any time, without notice to you. We may,
                        however, choose to notify you of a change to this
                        Policy, for example by announcing the change on the
                        Website. Your continued use of the Website constitutes
                        your consent to the contents of any updated policy.Where
                        we make significant changes to this Policy, we will
                        notify you directly of the changes by sending you an
                        email informing you of the changes, and will require
                        your consent prior to the changes coming into effect.
                        Significant changes include using your personal
                        information for a new purpose not anticipated originally
                        or a new disclosure of your personal information to a
                        third party for a purpose other than processing that is
                        integral to the delivery of a service.
                    </div>
                    <div>
                        <h2>Resolving Your Concerns</h2>If you would like more
                        information about this Policy or the personal
                        information collection, use and disclosure policies and
                        practices of Keel Mind or its service providers, please
                        contact our Privacy Officer by e-mail at:{" "}
                        <a href="mailto:privacy@keel.digital">
                            privacy@keel.digital
                        </a>
                        .
                    </div>
                    <div>
                        Please let us know your questions or concerns and we
                        will do our best to help you.
                    </div>
                </Policy>
            </main>
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;
