export const ourLeadership = [
    {
        name: "Rob Godfrey",
        title: "President & CEO",
        linkedinUrl: "https://www.linkedin.com/in/rob-godfrey-87bba02/",
        shortDescription:
            "Rob is a serial entrepreneur with a background in business development, strategic marketing, and government relations.",
        fullDescription: `Rob is a serial entrepreneur with a background in business development, strategic marketing, and government relations. Over the past 15+ years, he has successfully launched start-ups across sectors like chemical manufacturing (Qwatro USA), waste & soil remediation (Clean Soil), and pet services (Park9). Formerly SVP of the Toronto Blue Jays, Chair of the Ontario SPCA, and investment banker at TD Securities, Rob sits on various private and public boards and holds a JD/MBA from Pepperdine University.`,
    },
    {
        name: "Jay Fischbach",
        title: "Chief Operating Officer",
        linkedinUrl: "https://www.linkedin.com/in/jay-fischbach-15ba044/",
        shortDescription:
            "Jay has spent his career in capital markets and technology management working both nationally and internationally.",
        fullDescription: `Jay has spent his career in capital markets and technology management working both nationally and internationally. Jay brings a wealth of knowledge from his roles at RBC, Jameson Bank, AFEX and most recently as the COO of Exchange Bank of Canada. He currently advises on technology and innovation strategies to both start-ups and public companies. H.B.Sc. and an MBA in Technology Management.`,
    },
    {
        name: "Ahad Bandealy",
        title: "Chief Digital Officer",
        linkedinUrl: "https://www.linkedin.com/in/ahad-bandealy-a1394b139/",
        shortDescription:
            "Ahad has spent his career in behavioral health and psychology, working both nationally and internationally.",
        fullDescription: `Ahad has spent his career in behavioral health and psychology, working both nationally and internationally. Ahad brings a wealth of knowledge from his roles as a Ph.D. student, researcher, and practitioner-in-training at the University of Toronto and recently as the Founder & CEO of Get A-Head Inc. B.Sc. in psychology, M.Sc. in Exercise Science & Behavioral Health, Certification in Artificial Intelligence from MIT, MBA in Leadership and Healthcare Technologies.`,
    },
    {
        name: "Daniel Libertucci",
        title: "Chief Scientific Officer, Board member",
        linkedinUrl: "https://www.linkedin.com/in/daniel-libertucci-b977768/",
        shortDescription:
            "Daniel has dedicated his career to the field of clinical diagnostics.",
        fullDescription: `Daniel has dedicated his career to the field of clinical diagnostics. Having worked at Dynacare, Mount Sinai, Pharmacia Diagnostics and AFFINITY Diagnostics, he brings vast expertise in medical devices and clinical laboratory services. His background includes Health Canada device licensing, assay development, assay production, high-throughput automation and ISO 15189 accreditation. H.B.Sc. in Laboratory Medicine and Pathobiology from University of Toronto.`,
    },
    {
        name: "Kelly Ehler",
        title: "Chief Financial Officer",
        linkedinUrl: "https://www.linkedin.com/in/kellyehler/",
        shortDescription:
            "Kelly Ehler brings over 30 years of experience in global finance, including as a CFO, and Board Member.",
        fullDescription: `Kelly Ehler brings over 30 years of experience in global finance, including as a CFO, and Board Member. With an extensive background in both public and private companies he brings a wealth and depth of knowledge in IPOs, RTOs, CPCs, Compliance, Debt Financing, Equity Structuring and M&A. His experience ranges from sectors as diverse as healthcare, mining and cannabis, and across jurisdictions in Canada, the US, Asia and Africa. He is a licensed CA, TEP (Trust Estate Practitioner) and LLQP.`,
    },
];

export const ourAdvisors = [
    {
        name: "Rob Godfrey",
        role: "Board member",
        title: "Chairman",
        linkedinUrl: "https://www.linkedin.com/in/rob-godfrey-87bba02/",
        shortDescription:
            "Rob is a serial entrepreneur with a background in business development, strategic marketing, and government relations.",
        fullDescription: `Rob is a serial entrepreneur with a background in business development, strategic marketing, and government relations. Over the past 15+ years, he has successfully launched start-ups across sectors like chemical manufacturing (Qwatro USA), waste & soil remediation (Clean Soil), and pet services (Park9). Formerly SVP of the Toronto Blue Jays, Chair of the Ontario SPCA, and investment banker at TD Securities, Rob sits on various private and public boards and holds a JD/MBA from Pepperdine University.`,
    },
    {
        name: "Peter AP Zakarow",
        role: "Board member",
        title: "Vice Chair & Audit Chair",
        linkedinUrl:
            "https://www.linkedin.com/in/peterzakarow/?originalSubdomain=ca",
        shortDescription:
            "Peter is a passionate entrepreneur and business leader.",
        fullDescription: `Peter is a passionate entrepreneur and business leader. Over 25 years he has launched multiple successful innovation companies and is a trusted CEO advisor to a wide array of global companies, including IBM, Deloitte, Google, SAP, VMware, and Wipro, as well as to many political leaders.
    Peter also has extensive Board experience across a wide range of private and public organizations, contributing his governance expertise, objective strategic insights and mentorship to top executive teams across technology, health care, sports governing bodies, government tribunals, and global retailers.
    `,
    },
    {
        name: "Daniel Libertucci",
        role: "Board member",
        title: "Board member",
        linkedinUrl: "https://www.linkedin.com/in/daniel-libertucci-b977768/",
        shortDescription:
            "Daniel has dedicated his career to the field of clinical diagnostics.",
        fullDescription: `Daniel has dedicated his career to the field of clinical diagnostics. Having worked at Dynacare, Mount Sinai, Pharmacia Diagnostics and AFFINITY Diagnostics, he brings vast expertise in medical devices and clinical laboratory services. His background includes Health Canada device licensing, assay development, assay production, high-throughput automation and ISO 15189 accreditation. H.B.Sc. in Laboratory Medicine and Pathobiology from University of Toronto.`,
    },
    {
        name: "Pete Montour",
        role: "Board member",
        title: "Board member",
        description: "",
    },
    {
        name: "Henry Dreifus",
        role: "Board member",
        title: "Board member",
        description: "",
        linkedinUrl: "https://www.linkedin.com/in/henrydreifus/",
    },
    {
        name: "Janet Kerr",
        role: "Advisor",
        title: "Advisor",
        description: `Janet is Vice-Chancellor of Pepperdine University and Professor Emeritus of Law at Pepperdine University School of Law where she taught for 30 years. At Pepperdine, Janet founded and was the first Executive Director of the Palmer Center for Entrepreneurship & the Law, was the first professor to hold the Laure Sudreau-Rippe Endowed Chair and served as Associate Dean of the Law School. Janet has additionally had extensive experience with public boards in the areas of retail, technology, food industry and the financial sector; currently sitting on three public boards.`,
        linkedinUrl: "https://www.linkedin.com/in/janet-kerr-22221b3/",
    },
    {
        name: "Eric Hargan",
        role: "Advisor",
        title: "Advisor",
        description: `Eric Hargan served as the Deputy Secretary of the Department of Health and Human Services (HHS) from 2017 to 2021. With oversight of a $1.3 trillion annual budget and 80,000 employees across 26 divisions, he played a pivotal role in developing and approving regulations for HHS, CMS, and FDA. Notably, Eric served on the Board of Operation Warp Speed. He initiated the $175 billion Provider Relief Fund in response to the pandemic and facilitated the comprehensive HHS telehealth response.

Eric also led the Regulatory Sprint to Coordinated Care, reforming regulations for better-coordinated care, and played a key role in the launch of the interoperability rule. Additionally, he spearheaded the Innovation and Investment Summit, focusing on healthcare innovation, and led various initiatives in public health, including rural health reforms and participation in the WHO’s High-Level Commission on Non-Communicable Diseases.
`,
        linkedinUrl: "https://www.linkedin.com/in/eric-hargan-6a54ba7",
    },
    {
        name: "Sandy Climan",
        role: "Advisor",
        title: "Advisor",
        description: `As a graduate of Harvard (MBA and MS Health Policy) Sandy has managed to succeed in both the Private sector and in Hollywood.  He was the EVP of Universal Studios, CEO of 3ality Digital and President of Entertainment Media Ventures.  Mr. Climan has held senior management positions in the media and entertainment industry throughout his career and is considered a "media visionary”.  In addition, he has been an active investor in, and advisor to, venture capital firms and early-stage companies in media, technology, and healthcare.`,
        linkedinUrl: "https://www.linkedin.com/in/sandycliman/",
    },

    {
        name: "Dr. Georgia Witkin",
        role: "Advisor",
        title: "Advisor",
        description: `After completing post-doctoral studies in behavioral genetics as a National Science Foundation Fellow at the New York State Psychiatric Institute, Columbia College of Physicians and Surgeons, Dr. Georgia Witkin joined the Department of Psychiatry at the Mount Sinai School of Medicine in New York City where she is an Assistant Professor of Psychiatry, Assistant Professor of Obstetrics, Gynecology and Reproductive Sciences, and Director of Psychological and Wellness Services for Reproductive Medicine Associates of New York.`,
        linkedinUrl: "https://www.linkedin.com/in/dr-georgia-witkin/",
    },
];
