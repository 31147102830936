import styles from "./ToolCard.module.css";
function ToolCard({ name, iconSrc, iconAlt, description, reference }) {
    return (
        <div className={styles.tool_card} ref={reference}>
            <img src={iconSrc} alt={iconAlt} />
            <div className={styles.bottom}>
                <strong>{name}</strong>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default ToolCard;
