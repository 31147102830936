export const mindTestimonials = [
    {
        text: "“It is my belief that the potential benefits and improvements this platform can bring to the mental health care services within our community are substantial. Over the period of its use, I have been pleased with the platform's performance and the tangible benefits it has delivered to our community members.”",
        name: "Billy Joe Strang",
        title: "CEO Pikangikum Health Authority",
    },
    {
        text: "“I have been working clinically with Keel Mind for 4 years. This platform has undeniably increased my capacity and efficiency in conducting my clinical practice and program of psychotherapy research. I am very excited about the human delivered-AI enhanced model of integrating technology into psychotherapy in a manner that is safe and responsible, yet innovative and forward-thinking. Keel has also empowered me to expand my clinical practice geographically, where we have been working with Northern and Indigenous communities. I am humbled and honoured to have been invited into these spaces, where we collaboratively provide culturally sensitive care in regions that have limited resources when it comes to mental health support. I presently have a number of projects on the go with Keel, and I am excited to see how we can continue to support clients in innovative ways.”",
        name: "Dr. Dillon Browne, C.Psych",
        title: "Founder & Director, FamilyPsycle Institute, University of Waterloo",
        width: 794,
    },
    {
        text: "placeholder text",
        name: "Placeholder name",
        title: "placeholder title",
    },
    {
        text: "placeholder text",
        name: "Placeholder name",
        title: "placeholder title",
    },
    {
        text: "placeholder text",
        name: "Placeholder name",
        title: "placeholder title",
    },
];
