export const labsFAQ = [
    {
        q: "Why saliva? Meaning, what’s the difference between the values I see from a regular blood test and those from a saliva test?",
        a: "First, saliva sampling is very convenient because it is non-invasive which allows multiple samples to be collected throughout the day at home, or at work. Second, blood testing provides a measurement of the total amount of hormone in the body, while saliva testing only measures the quantity of the free (i.e. biologically active fraction) of the circulating hormone which correlates best with functional activity.",
    },
    {
        q: "If I’m collecting this at home and sending it back via courier, how stable is my saliva sample in transit?",
        a: "PLACEHOLDER",
    },
    {
        q: "Do I need to fast before providing my saliva sample?",
        a: "PLACEHOLDER",
    },
    {
        q: "Do I need to stop taking all my medication before providing my sample?",
        a: "PLACEHOLDER",
    },
    {
        q: "My doctor has never heard of this type of test. Now what do I do?",
        a: "PLACEHOLDER",
    },
];

export const mindFAQ = [
    {
        q: "Why saliva? Meaning, what’s the difference between the values I see from a regular blood test and those from a saliva test?",
        a: "First, saliva sampling is very convenient because it is non-invasive which allows multiple samples to be collected throughout the day at home, or at work. Second, blood testing provides a measurement of the total amount of hormone in the body, while saliva testing only measures the quantity of the free (i.e. biologically active fraction) of the circulating hormone which correlates best with functional activity.",
    },
    {
        q: "If I’m collecting this at home and sending it back via courier, how stable is my saliva sample in transit?",
        a: "PLACEHOLDER",
    },
    {
        q: "Do I need to fast before providing my saliva sample?",
        a: "PLACEHOLDER",
    },
    {
        q: "Do I need to stop taking all my medication before providing my sample?",
        a: "PLACEHOLDER",
    },
    {
        q: "My doctor has never heard of this type of test. Now what do I do?",
        a: "PLACEHOLDER",
    },
];
