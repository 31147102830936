import { useState } from "react";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import { navLinks } from "../../data/navigation";

function Header({
    dark = false,
    logoSrcDark = "/images/keel-logo-blue.svg",
    logoSrcLight = "/images/keel-logo-white.svg",
}) {
    const [showMenu, setShowMenu] = useState(false);

    function handleOpenClick() {
        setShowMenu(true);
    }

    return (
        <header id={styles.header}>
            <HamburgerMenu
                data={navLinks}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
            />
            <div id={styles.left}>
                <Link
                    to={"/"}
                    aria-label="Link to Keel Digital Solutions home page"
                >
                    <img
                        src={dark ? logoSrcDark : logoSrcLight}
                        alt="Keel logo"
                        id={styles.keel_logo}
                    />
                </Link>
            </div>
            <div id={styles.right}>
                <nav id={styles.middle}>
                    {navLinks
                        .filter((l) => !l.hamburgerOnly)
                        .map((pg) => (
                            <Link
                                key={pg.name}
                                className={[
                                    styles.navlink,
                                    dark ? styles.dark_text : null,
                                ].join(" ")}
                                to={pg.href}
                            >
                                {pg.name}
                            </Link>
                        ))}
                </nav>
                {/* <Link
                    className={[
                        styles.header_button,
                        "outline_button",
                        dark ? styles.dark_text : null,
                    ].join(" ")}
                    to="/sign-in"
                >
                    Sign in
                </Link> */}
                <Link
                    id={styles.contact_us}
                    className={[styles.header_button, "orange_button"].join(
                        " "
                    )}
                    to="/contact-us"
                >
                    Contact Us
                    <img src="/images/up-right-arrow.svg" alt="" />
                </Link>
            </div>
            <div id={styles.hamburger_button} onClick={handleOpenClick}>
                <img
                    src={
                        dark
                            ? "/images/hamburger-button-blue.svg"
                            : "/images/hamburger-button-white.svg"
                    }
                    alt="hamburger menu button with three horizontal lines"
                />
            </div>
        </header>
    );
}

export default Header;
