export const navLinks = [
    // { name: "Mind", href: "/mind" },
    // { name: "Lab", href: "/lab" },
    { name: "About us", href: "/about-us" },
    { name: "Our team", href: "/our-team" },
    { name: "Contact us", href: "/contact-us", hamburgerOnly: true },
];

export const footerLinks = [
    {
        name: "Company",
        children: [
            // {
            //     name: "Mind",
            //     href: "/mind",
            // },
            // {
            //     name: "Lab",
            //     href: "/lab",
            // },
            { name: "About us", href: "/about-us" },
            { name: "Our team", href: "/our-team" },
            {
                name: "Keelmind.com",
                href: "https://keelmind.com",
                target: "_blank",
            },
        ],
    },
    {
        name: "Legal",
        children: [
            { name: "Privacy Policy", href: "/privacy-policy" },
            {
                name: "Terms of Use",
                href: "/terms-of-use",
            },
            {
                name: "Terms & Conditions",
                href: "/terms-and-conditions",
            },
        ],
    },
    // {
    //     name: "Contact us",
    //     children: [
    //         { name: "admin@keel.digital", href: "mailto:admin@keel.digital" },
    //     ],
    // },
];
