import Header from "../../components/Header/Header";
import styles from "./HomePage.module.css";
import Footer from "../../components/Footer/Footer";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import SolutionComponent from "../../components/SolutionComponent/SolutionComponent";
import TabbedCarousel from "../../components/TabbedCarousel/TabbedCarousel";
import VideoHero from "../../components/VideoHero/VideoHero";
import { useEffect } from "react";
import { ourApproach } from "../../data/verticals";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import TabbedAccordion from "../../components/TabbedAccordion/TabbedAccordion";
import WhiteTextSection from "../../components/WhiteTextSection/WhiteTextSection";
import OverlayedImage from "../../components/OverlayedImage/OverlayedImage";
import Overlay from "../../components/OverlayedImage/Overlay";
// import keelLabIconSmall from "../assets";

function HomePage() {
    useEffect(() => {
        document.title = "Home | Keel Digital Solutions";
    }, []);

    return (
        <div id={styles.home}>
            <Header />
            <main>
                <VideoHero id={styles.hero}>
                    <div id={styles.hero_card}>
                        <ArrowSubtitle
                            text={"Welcome to Keel Digital Solutions"}
                        />
                        <div id={styles.hero_card_top}>
                            <h1>Practice. Analytics. Life.</h1>
                        </div>
                        <div id={styles.hero_subtitle}>
                            <div>
                                We believe in building a future where mental
                                health
                            </div>
                            <div>goes beyond... "how do you feel?"</div>
                        </div>
                    </div>
                    <div id={styles.hero_images_overflow_container}>
                        <div id={styles.hero_images}>
                            <img
                                src="/images/home-hero-image-1.png"
                                alt="Child smiling warmly at a caregiver in a close embrace."
                            />
                            <img
                                src="/images/home-hero-image-2.png"
                                alt="Woman wearing glasses using a laptop."
                            />
                            <img
                                src="/images/home-hero-image-3.png"
                                alt="Man using a stylus with a tablet, with a checklist and line chart on its screen."
                            />
                            <img
                                src="/images/home-hero-image-1.png"
                                alt="Child smiling warmly at a caregiver in a close embrace."
                            />
                        </div>
                    </div>
                </VideoHero>
                <WhiteTextSection arrowText={"What we do"}>
                    Welcome to a world where mental health is no longer just
                    about asking, <span>“How do you feel?”</span> Keel Digital
                    Solutions integrates AI-driven metrics with physical
                    biomarkers to affirm: <span>“What you feel is real.”</span>
                </WhiteTextSection>
                <div id={styles.home_content}>
                    <section id={styles.our_solutions}>
                        <div className={styles.solutions_container}>
                            <SolutionComponent
                                shortenText={true}
                                heading={"Keel Mind"}
                                iconSrc={"/images/keel-mind-icon-small.svg"}
                                Graphic={() => (
                                    <OverlayedImage
                                        src={
                                            "/images/keel-mind-solution-base-image.png"
                                        }
                                    >
                                        <Overlay
                                            bottom={0}
                                            left={0}
                                            translate={{ x: "-50%", y: "20%" }}
                                            src="/images/overlays/keel-mind-solution-overlay-image-1.png"
                                            alt=""
                                        />
                                        <Overlay
                                            top={10}
                                            left={"calc(100% - 55px)"}
                                            mediumResponsive={{
                                                top: -30,
                                                left: 0,
                                                translate: { x: "50%" },
                                                maxWidth: "85%",
                                            }}
                                            blur={2}
                                            src="/images/overlays/keel-mind-solution-overlay-image-2.png"
                                            alt=""
                                        />
                                        <Overlay
                                            top={85}
                                            left={"calc(100% - 22px)"}
                                            blur={6}
                                            src="/images/overlays/keel-mind-solution-overlay-image-3.png"
                                            alt=""
                                            mediumResponsive={{
                                                top: "50%",
                                                left: 0,
                                                translate: { x: "50%" },
                                                maxWidth: "85%",
                                            }}
                                        />
                                    </OverlayedImage>
                                )}
                                buttonHref={"https://www.keelmind.com"}
                            >
                                An AI-driven platform that facilitates mental
                                health training and education while delivering
                                measurement-based care.
                            </SolutionComponent>
                            <SolutionComponent
                                shortenText={true}
                                reversed={true}
                                heading={"Keel Lab"}
                                iconSrc={"/images/keel-lab-icon-small.svg"}
                                buttonHref={"/contact-us"}
                                Graphic={() => (
                                    <OverlayedImage
                                        src={
                                            "/images/keel-labs-solution-base-image.png"
                                        }
                                        alt={""}
                                    >
                                        <Overlay
                                            src={
                                                "/images/overlays/keel-labs-solution-overlay-image-1.png"
                                            }
                                            blur={2}
                                            top={30}
                                            left={-95}
                                            mediumResponsive={{
                                                top: -30,
                                                left: -20,
                                            }}
                                        />
                                        <Overlay
                                            src={
                                                "/images/overlays/keel-labs-solution-overlay-image-2.png"
                                            }
                                            blur={8}
                                            bottom={-35}
                                            right={-100}
                                            mediumResponsive={{
                                                right: -20,
                                            }}
                                        />
                                    </OverlayedImage>
                                )}
                            >
                                Spit into the Future…Keel’s saliva based lab
                                provides the necessary biomarkers to measure
                                stress and hormone levels affecting mental
                                health.
                            </SolutionComponent>
                        </div>
                    </section>
                    <section id={styles.our_approach}>
                        <SectionHeading
                            arrowText={"Our approach"}
                            darkArrowSubtitle={true}
                            heading={
                                "Keel Digital Solutions is a business to government and government-agency company"
                            }
                        >
                            Four key verticals:
                        </SectionHeading>
                        <TabbedCarousel
                            data={ourApproach}
                            className={"small-hide"}
                        />
                        <TabbedAccordion
                            data={ourApproach}
                            className={"small-show"}
                        />
                    </section>
                    <section id={styles.our_evidence}>
                        <div
                            className="big_dark_card"
                            id={styles.evidence_card}
                        >
                            <SectionHeading
                                arrowText={"Our evidence"}
                                heading={
                                    "Dedicated to refining solutions for consistent and impactful results"
                                }
                                headingStyle={{
                                    color: "white",
                                }}
                                textStyle={{ color: "white" }}
                            ></SectionHeading>
                            <div className={styles.evidence_container}>
                                <div className={styles.evidence}>
                                    <strong>200k+</strong>
                                    <p>
                                        Sessions provided to
                                        <br />
                                        individuals in need of care
                                    </p>
                                </div>
                                <div className={styles.evidence}>
                                    <strong>20k+</strong>
                                    <p>
                                        Saliva tests taken
                                        <br />
                                        with quick results
                                    </p>
                                </div>
                                <div className={styles.evidence}>
                                    <strong>85k+</strong>
                                    <p>
                                        Clients helped using <br />
                                        Keel solutions
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default HomePage;
