import styles from "./Policy.module.css";

function Policy({ title, children }) {
    return (
        <div id={styles.main}>
            <h1 id={styles.title}>{title}</h1>
            <div id={styles.policy_container}>{children}</div>
        </div>
    );
}

export default Policy;
