import { useEffect, useRef, useState } from "react";
import styles from "./OverlayedImage.module.css";
function Overlay({
    top,
    bottom,
    left,
    right,
    translate = {},
    borderRadius = 16,
    shadow = {
        y: 10,
        blur: 24,
        color: "#00123829",
    },
    dropShadow = false,
    maxWidth,
    blur,
    src,
    alt = "",
    mediumResponsive = {},
    // smallResponsive = {},
}) {
    const medium = {
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        translate: translate,
        maxWidth: maxWidth,
        ...mediumResponsive,
    };
    // const small = {
    //     ...medium,
    //     ...smallResponsive,
    // };

    const [isVisible, setIsVisible] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        const current = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible((prev) => (prev ? true : entry.isIntersecting));
            },
            {
                root: null,
                threshold: 1,
            }
        );

        if (current) {
            observer.observe(current);
        }

        return () => {
            if (current) {
                observer.unobserve(current);
            }
        };
    }, []);

    function num(n) {
        return `${n}${isNaN(n) ? "" : "px"}`;
    }

    function formatTranslate(translate) {
        return `${num(translate?.x || "0")} ${num(translate?.y || "0")}`;
    }

    function formatShadow(shadow) {
        return `${num(shadow?.x || 0)} ${num(shadow?.y || 0)} ${num(
            shadow?.blur || 0
        )} ${shadow?.color || "black"}`;
    }

    function formatBoxShadow(boxShadow) {
        return formatShadow(boxShadow);
    }

    function formatDropShadow(dropShadow) {
        return `drop-shadow(${formatShadow(dropShadow)})`;
    }

    function formatBlur(blur) {
        return `blur(${num(blur)})`;
    }

    const styleObj = {
        "--top": num(top),
        "--bottom": num(bottom),
        "--left": num(left),
        "--right": num(right),
        "--translate": formatTranslate(translate),
        "--maxWidth": maxWidth ? num(maxWidth) : "100%",
        "--mediumTop": num(medium.top),
        "--mediumBottom": num(medium.bottom),
        "--mediumLeft": num(medium.left),
        "--mediumRight": num(medium.right),
        "--mediumTranslate": formatTranslate(medium.translate),
        "--mediumMaxWidth": medium.maxWidth ? num(medium.maxWidth) : "100%",
        // "--smallTop": num(small.top),
        // "--smallBottom": num(small.bottom),
        // "--smallLeft": num(small.left),
        // "--smallRight": num(small.right),
        // "--smallTranslate": formatTranslate(small.translate),
        // "--smallMaxWidth": small.maxWidth
        //     ? num(small.maxWidth)
        //     : "100%",
        borderRadius,
        // boxShadow: formatBoxShadow(boxShadow),
        // filter: formatDropShadow(dropShadow),
        [dropShadow ? "filter" : "boxShadow"]: dropShadow
            ? formatDropShadow(shadow)
            : formatBoxShadow(shadow),
        backdropFilter: formatBlur(blur),
    };

    return (
        <img
            ref={ref}
            style={styleObj}
            src={src}
            alt={alt}
            className={[
                styles.overlay_img,
                isVisible ? "zoomFadeIn" : null,
            ].join(" ")}
        />
    );
}

export default Overlay;
