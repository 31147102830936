import Overlay from "./Overlay";

export function firstRespondersOverlays() {
    return (
        <>
            <Overlay
                src={"/images/overlays/first-responders-overlay-image-1.png"}
                top={10}
                left={-65}
                blur={6}
                maxWidth={"25%"}
                borderRadius={`${(100 * 16) / 129}%`}
                mediumResponsive={{ left: -30, maxWidth: "20%" }}
            />
            <Overlay
                src={"/images/overlays/first-responders-overlay-image-2.png"}
                right={-65}
                bottom={40}
                mediumResponsive={{
                    right: -40,
                    bottom: 20,
                }}
                blur={6}
                shadow={{ x: 25, y: 25, blur: 50, color: "#002C611A" }}
                dropShadow={true}
                borderRadius={"12px 25px 25px 12px"}
            />
        </>
    );
}
export function indigenousOverlays() {
    return (
        <>
            <Overlay
                src={"/images/overlays/indigenous-overlay-image-1.png"}
                blur={6}
                right={-70}
                mediumResponsive={{
                    right: -40,
                    top: 20,
                }}
                maxWidth={"50%"}
                top={45}
                // dropShadow={true}
                borderRadius={`${(100 * 16) / 260}% / ${(100 * 16) / 67}%`}
            />
            <Overlay
                src={"/images/overlays/indigenous-overlay-image-2.png"}
                blur={6}
                left={-70}
                mediumResponsive={{
                    left: -40,
                    maxWidth: "25%",
                }}
                bottom={30}
                maxWidth={"27%"}
                dropShadow={true}
                borderRadius={`${(100 * 16) / 140}% / ${(100 * 16) / 158}%`}
            />
        </>
    );
}
export function academiaOverlays() {
    return (
        <>
            <Overlay
                src={"/images/overlays/academia-overlay-image-1.png"}
                borderRadius={`${(100 * 16) / 140}% / ${(100 * 16) / 158}%`}
                maxWidth={"27%"}
                left={0}
                translate={{ x: "-50%" }}
                top={15}
                blur={6}
                mediumResponsive={{
                    left: 20,
                    maxWidth: "25%",
                }}
            />
            <Overlay
                src={"/images/overlays/academia-overlay-image-2.png"}
                maxWidth={"43%"}
                right={-65}
                bottom={35}
                blur={6}
                borderRadius={`${(100 * 16) / 224}% / ${(100 * 16) / 60}%`}
                mediumResponsive={{
                    right: -30,
                }}
            />
        </>
    );
}
export function k12Overlays() {
    return (
        <>
            <Overlay
                src={"/images/overlays/k12-overlay-image-1.png"}
                maxWidth={"27%"}
                left={0}
                top={30}
                translate={{ x: "-50%" }}
                blur={6}
                borderRadius={`${(100 * 16) / 141}% / ${(100 * 16) / 154}%`}
                mediumResponsive={{
                    left: 20,
                }}
            />
            <Overlay
                src={"/images/overlays/k12-overlay-image-2.png"}
                right={-40}
                bottom={35}
                maxWidth={"23%"}
                borderRadius={`${(100 * 16) / 120}% / ${(100 * 16) / 124}%`}
                blur={6}
            />
        </>
    );
}
