import styles from "./ArrowSubtitle.module.css";

function ArrowSubtitle({ text, dark }) {
    return (
        <div
            className={[styles.arrow_subtitle, dark ? styles.dark : null].join(
                " "
            )}
        >
            <img
                className={styles.arrow_image}
                src="/images/arrow_subtitle_arrow.png"
                alt=""
            />
            <span className={styles.text}>{text}</span>
        </div>
    );
}

export default ArrowSubtitle;
