import { useRef, useState } from "react";
import styles from "./ContactForm.module.css";

function ContactForm() {
    const [formState, setFormState] = useState("input"); // input, success, error
    const [fieldErrors, setFieldErrors] = useState({});
    const formRef = useRef(null);

    function validateField(target) {
        const validity = target.validity;
        if (validity.valid) {
            return undefined;
        } else {
            target.classList.add(styles.has_error);
            return `${target.validationMessage} ${
                target.getAttribute("title") || ""
            }`;
        }
    }

    function handleValidate() {
        const inputs = [
            ...formRef.current.querySelectorAll("." + styles.input),
        ];

        setFieldErrors(() => {
            const newFieldErrors = {};
            inputs.forEach((i) => {
                newFieldErrors[i.getAttribute("name")] = validateField(i);
            });
            return newFieldErrors;
        });
    }

    function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData(e.target);

        if (
            document.querySelectorAll("." + styles.input + ":invalid")
                .length === 0
        ) {
            const url = encodeURI(
                `https://docs.google.com/forms/d/e/1FAIpQLScSy0BSZoLxoFJVdGGEvuSvDDUPEwXRHJfHRxhfjz8Dj-k-Ng/formResponse?&submit=Submit?usp=pp_url&entry.1494138662=${formData.get(
                    "first_name"
                )}&entry.1333345587=${formData.get(
                    "last_name"
                )}&entry.121070252=${formData.get(
                    "email"
                )}&entry.1440973355=${formData.get(
                    "phone_number"
                )}&entry.1571956624=${formData.get(
                    "area_of_interest"
                )}&entry.1541492610=${formData.get(
                    "country"
                )}&entry.1573250047=${formData.get("message")}`
            );

            fetch(url, {
                method: "GET",
                mode: "no-cors",
            })
                .then(() => {
                    setFormState("success");
                })
                .catch(() => {
                    setFormState("error");
                });
        }
    }

    return {
        success: (
            <div id={styles.contact_form}>
                <div className={styles.submission_info} id={styles.success}>
                    <strong>
                        Thank you for your interest in Keel Digital Solutions.
                    </strong>
                    <div>Someone will be in touch with you shortly</div>
                </div>
            </div>
        ),
        error: (
            <div id={styles.contact_form}>
                <div className={styles.submission_info} id={styles.error}>
                    <strong>
                        There was a submission error with your form.
                    </strong>
                    <div>
                        Please reload the page or contact{" "}
                        <a href="mailto:admin@keel.digital">
                            admin@keel.digital
                        </a>
                        .
                    </div>
                </div>
            </div>
        ),
        input: (
            <form
                ref={formRef}
                id={styles.contact_form}
                onSubmit={handleSubmit}
            >
                <Input
                    label={"First name"}
                    type={"text"}
                    name={"first_name"}
                    placeholder={"Enter first name"}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <Input
                    label={"Last name"}
                    type={"text"}
                    name={"last_name"}
                    placeholder={"Enter last name"}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <Input
                    label={"Email"}
                    type={"email"}
                    name={"email"}
                    placeholder={"name@company.com"}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <Input
                    label={"Phone number"}
                    name={"phone_number"}
                    type={"tel"}
                    placeholder={"123-555-1234"}
                    extraProps={{
                        pattern: "[0-9]{3}[\\- ]?[0-9]{3}[\\- ]?[0-9]{4}",
                        title: "Format: 123-555-1234",
                    }}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <Input
                    label={"Area of Interest"}
                    name={"area_of_interest"}
                    placeholder={"Which solution interests you?"}
                    type={"select"}
                    options={[
                        { value: "Keel Mind", label: "Keel Mind" },
                        { value: "Keel Thrive", label: "Keel Thrive" },
                        {
                            value: "Keel Digital Solutions",
                            label: "Keel Digital Solutions",
                        },
                        { value: "Careers", label: "Careers" },
                    ]}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <Input
                    label={"Country"}
                    name={"country"}
                    type={"select"}
                    placeholder={"Please select a country"}
                    options={[
                        {
                            value: "Canada",
                            label: "Canada",
                        },
                        {
                            value: "United States",
                            label: "United States",
                        },
                    ]}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <Input
                    label={"Message"}
                    name={"message"}
                    type={"textarea"}
                    placeholder={"Enter a message"}
                    errors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                />
                <button
                    type="submit"
                    className="orange_button"
                    id={styles.submit}
                    onClick={handleValidate}
                >
                    Get in touch
                </button>
            </form>
        ),
    }[formState];
}

export default ContactForm;

function Input({
    label,
    name,
    type,
    required = true,
    placeholder,
    options = [],
    errors,
    extraProps = {},
    setFieldErrors,
}) {
    const fieldError = errors?.[name];
    const containerRef = useRef(null);
    function handleClick() {
        setFieldErrors((prev) => {
            return { ...prev, [name]: undefined };
        });
    }

    switch (type) {
        case "textarea":
            return (
                <label
                    ref={containerRef}
                    onClick={handleClick}
                    className={[
                        styles.input_container,
                        fieldError ? styles.has_error : null,
                    ].join(" ")}
                >
                    <strong>
                        {label}
                        {required && "*"}
                    </strong>
                    <textarea
                        className={styles.input}
                        name={name}
                        required={required}
                        placeholder={placeholder}
                        {...extraProps}
                    ></textarea>
                    <strong className={styles.error_text}>{fieldError}</strong>
                </label>
            );
        case "select":
            return (
                <label
                    ref={containerRef}
                    onClick={handleClick}
                    className={[
                        styles.input_container,
                        fieldError ? styles.has_error : null,
                    ].join(" ")}
                >
                    <strong>
                        {label}
                        {required && "*"}
                    </strong>
                    <select
                        className={styles.input}
                        name={name}
                        required={required}
                        defaultValue={""}
                        {...extraProps}
                    >
                        <option value="" disabled>
                            {placeholder}
                        </option>
                        {options.map((o) => (
                            <option key={o.value} value={o.value}>
                                {o.label}
                            </option>
                        ))}
                    </select>
                    <strong className={styles.error_text}>{fieldError}</strong>
                </label>
            );
        default:
            return (
                <label
                    ref={containerRef}
                    onClick={handleClick}
                    className={[
                        styles.input_container,
                        fieldError ? styles.has_error : null,
                    ].join(" ")}
                >
                    <strong>
                        {label}
                        {required && "*"}
                    </strong>
                    <input
                        className={styles.input}
                        type={type}
                        name={name}
                        required={required}
                        placeholder={placeholder}
                        {...extraProps}
                    />
                    <strong className={styles.error_text}>{fieldError}</strong>
                </label>
            );
    }
}
