import React, { useCallback, useEffect, useRef } from "react";
import styles from "./Timeline.module.css";
import useScrollPosition from "../../hooks/useScrollPosition";
import ScrollAnimation from "react-animate-on-scroll";

function Timeline({ data }) {
    const scrollPosition = useScrollPosition();
    const outerRef = useRef(null);
    const fullProgressBarRef = useRef(null);

    const shrinkProgressBar = useCallback(() => {
        const timelineSection = outerRef.current.offsetParent.offsetParent;
        const bottomOffset =
            timelineSection.offsetTop + timelineSection.offsetHeight;

        const bottomBuffer =
            window.innerHeight / 2 - (window.innerHeight / 10) * 2;

        const lastCircleBottom = bottomOffset - bottomBuffer * 2;

        if (scrollPosition >= lastCircleBottom) {
            fullProgressBarRef.current.style.height = `calc(var(--full-progress-bar-height) - ${
                scrollPosition - lastCircleBottom
            }px)`;
        } else {
            fullProgressBarRef.current.style.height =
                "var(--full-progress-bar-height)";
        }
    }, [scrollPosition]);

    useEffect(() => {
        document.addEventListener("resize", () => shrinkProgressBar);

        return document.removeEventListener("resize", () =>
            shrinkProgressBar()
        );
    }, [shrinkProgressBar]);

    useEffect(() => {
        shrinkProgressBar();
    }, [scrollPosition, shrinkProgressBar]);

    return (
        <div className={styles.timeline_outer} ref={outerRef}>
            <div
                className={[
                    styles.empty_progress_bar,
                    styles.progress_bar,
                ].join(" ")}
            ></div>
            <div
                ref={fullProgressBarRef}
                className={[styles.full_progress_bar, styles.progress_bar].join(
                    " "
                )}
            ></div>
            <div
                className={[
                    styles.progress_bar_cover,
                    styles.progress_bar_top_cover,
                    styles.progress_bar,
                ].join(" ")}
            ></div>
            <div
                className={[
                    styles.progress_bar_cover,
                    styles.progress_bar_bottom_cover,
                    styles.progress_bar,
                ].join(" ")}
            ></div>
            <div className={styles.timeline_container}>
                {data.map((n, index) => (
                    <React.Fragment key={index}>
                        <TimelineNode
                            data={n}
                            side={"left"}
                            visibility={index % 2 !== 0}
                        />
                        <div className={styles.middle_node}>
                            <div className={styles.circle}></div>
                            {/* <div className={styles.line}></div> */}
                        </div>
                        <TimelineNode
                            data={n}
                            side={"right"}
                            visibility={index % 2 === 0}
                        />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

function TimelineNode({ data, side, visibility }) {
    return (
        <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            offset={100}
            animatePreScroll={true}
        >
            <div
                key={`${data.title} ${side}`}
                className={[styles.data_node, styles[side]].join(" ")}
                style={{
                    visibility: visibility ? "visible" : "hidden",
                }}
            >
                <div className={styles.year}>{data.year}</div>
                <h2 className={styles.title}>{data.title}</h2>
                <p className={styles.body}>{data.description}</p>
            </div>
        </ScrollAnimation>
    );
}

export default Timeline;
