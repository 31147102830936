import OverlayedImage from "../OverlayedImage/OverlayedImage";
import styles from "./TabbedAccordion.module.css";
import { useState } from "react";

function TabbedAccordion({ data, className }) {
    const [currentTab, setCurrentTab] = useState(0);

    function handleTabClick(index) {
        setCurrentTab(index);
    }

    return (
        <div className={[styles.tabbed_accordion, className].join(" ")}>
            {Object.entries(data).map(([key, content], index) => (
                <div
                    key={key}
                    onClick={() => handleTabClick(index)}
                    className={[
                        styles.entry_section,
                        index === currentTab ? styles.active : null,
                    ].join(" ")}
                >
                    <button
                        className={styles.tab}
                        aria-expanded={index === currentTab}
                    >
                        <span className={styles.tab_index}>
                            {(index + 1).toString().padStart(2, "0")}
                        </span>
                        <div className={styles.tab_label}>{key}</div>
                    </button>
                    <div className={styles.content}>
                        <OverlayedImage src={content.baseImg} alt={content.alt}>
                            {content.overlays()}
                        </OverlayedImage>
                        <div className={styles.description}>
                            {content.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TabbedAccordion;
