import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import styles from "./WhiteTextSection.module.css";

function WhiteTextSection({ arrowText, children, style, reference }) {
    return (
        <section ref={reference} id={styles.section} style={style}>
            <div id={styles.container}>
                <ArrowSubtitle text={arrowText} dark={true} />
                <h2 id={styles.text}>{children}</h2>
            </div>
        </section>
    );
}

export default WhiteTextSection;
